<template>
  <div>
    <div class="switch-content">
      <span>开放举手功能</span>
      <el-switch
        v-model="globalHandsUp"
        class="hand-switch"
        active-color="#3E74CA"
        inactive-color="rgba(255,255,255,0.20)"
        @change="handleChangeHandsUp"
      />
    </div>
    <template v-if="handsList.length">
      <scroll-bar
        class="hands-list main-scroll"
        :flex-layout="true"
      >
        <div
          v-for="(item, index) in handsList"
          :key="index"
          class="hands-item"
        >
          <div class="left">
            <div class="avatar-content">
              <logo-panel
                :info="item"
                class="avatar"
              />
            </div>
            <div class="name">
              {{ item.name || '新用户' }}
            </div>
          </div>
          <div class="right">
            <div
              class="operate"
              :class="{'disabled': item.disabled}"
              @click="handleAgreeHandInfo(item, index)"
            >
              <template>
                <i
                  v-if="item.disabled"
                  class="iconfont icon-v2_yes"
                />
                <i
                  v-else
                  class="iconfont icon-sil_plus_normal"
                />
              </template>

              <i class="iconfont icon-damaikai" />
            </div>
          </div>
        </div>
      </scroll-bar>
    </template>
    <div
      v-else
      class="no-empty"
    >
      暂无举手消息
    </div>
  </div>
</template>
<script>
import { setHandsResponse, setHandsType } from '@/services/room';
import LogoPanel from '@/views/components/LogoPanel';
import ScrollBar from '@/components/ScrollBar';

export default {
  name: '',
  components: {
    LogoPanel,
    ScrollBar,
  },
  props: {
    globalHandsUpStatus: {
      type: Boolean,
      required: true,
    },
    handsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      globalHandsUp: this.globalHandsUpStatus,
      roomId: this.$route.params.id,
    };
  },
  watch: {
    globalHandsUpStatus(newVal) {
      this.globalHandsUp = newVal;
    },
  },
  methods: {
    // 管理员开关举手功能
    async handleChangeHandsUp(val) {
      this.globalHandsUp = val;
      await setHandsType({
        liveRoomId: this.roomId,
        handsType: val ? 1 : 0, // 0关闭 1开启
      });
      this.$emit('hands-status-change', val);
    },

    // 同意观众举手
    async handleAgreeHandInfo(item, index) {
      if (item.disabled) return;
      this.handsList.splice(index, 1, {
        ...item,
        disabled: true,
      });
      this.handsList[index].disabled = true;

      await setHandsResponse({
        liveRoomId: this.roomId,
        status: 1,
        createId: item.uid,
      });

      this.$emit('agree-hand-apply', item.uid);
    },
  },
};
</script>
<style lang="scss" scoped>
.switch-content {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-right: 14px;
}

.no-empty {
  text-align: center;
  padding-top: 156px;
  font-size: 12px;
  color: #666b71;
  line-height: 18px;
}

.hands-list {
  max-height: 378px;
  overflow-y: auto;
  margin-top: 16px;
  padding-right: 14px;

  &.main-scroll {
    &::-webkit-scrollbar-track-piece {
      background: #151a28;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 3.5px;
    }
  }
}

.hands-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .left {
    display: flex;

    .name {
      line-height: 40px;
    }
  }

  .right {
    padding-top: 6px;
  }
}

.operate {
  width: 68px;
  height: 28px;
  background: #3e74ca;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 28px;

  &.disabled {
    background: rgba(255, 255, 255, 0.1);
  }

  .icon-damaikai {
    margin-left: 4px;
  }
}

/deep/ .user-logo-content {
  width: 40px;
  height: 40px;
}

.hand-switch {
  /deep/ .el-switch__core {
    border-color: #151a28 !important;
  }
}

.avatar {
  /deep/ .logo {
    height: 40px !important;
  }
}
</style>
